import {megaUrl, url} from "../helper";
import {IGlobalProduct} from "../types/state";
import {ICurrencies, ILocales} from "../components/ForCWV/Wrappers/MainLayout";
import {NotFoundPageTranslations} from "../services/translationKeys";

/******************* controllers import *************************/

const PageControllers = require("../controllers/PageControllers");
const ProductController = require("../controllers/ProductController");
const CategoryControllers = require("../controllers/CategoryController.js");
const FilterControllers = require("../controllers/FilterControllers");
const BuilderController = require("../controllers/BuilderController");
const SettingsController = require("../controllers/SettingsController.js");
const BlogControllers = require("../controllers/BlogControllers");
const FieldsController = require("../controllers/FieldsController");
const PostControllers = require("../controllers/PostControllers")
const FormControllers = require("../controllers/FormControllers")


const shopApi = {
    customerResetPassword: async (object: any) => {
        const response = await fetch(`${megaUrl}/api/customer/reset-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(object),
        });
        return await response.json();
    },

    getBrands: async ({
                          options = {
                              locale: ""
                          }
                      }: { options?: { locale: string } }) => {
        const response = await fetch(`${url}/db/attributes?code=brand&locale=${options.locale}`);
        return await response.json();
    },

    /**
     * Returns product.
     *
     * @param {string} slug
     *
     * @param options
     * @return {Promise<object>}
     */
    getProductBySlug: async ({slug, options}: {
        slug: string,
        options: any
    }): Promise<IGlobalProduct[]> => {
        const {token, selectedRate, lang} = options || {}
        const res = await fetch(`${megaUrl}/db/product/${slug}?token=${token}&locale=${lang ?? "en"}&rate=${JSON.stringify(selectedRate)}`);
        return await res.json();
    },

    /**
     * Returns an array with up-sell product
     *
     * @param productId
     * @param lang
     * @param currency
     * @param rate
     * @param selectedRate
     * @param type
     */

    getUpOrCrossSellProducts: async (productId: number, lang = "en", selectedRate: {
        exchange_rate: { rate: string },
        symbol: string,
        code: string
    }, type: string) => {
        const {exchange_rate: {rate: customRate}, symbol, code} = selectedRate
        const queryParams = new URLSearchParams({
            limit: "8",
            product_id: productId.toString(),
            locale: lang,
            currency: code,
            rate: customRate.toString(),
            symbol,
        });
        const url = `${megaUrl}/db/${type}-products?${queryParams.toString()}`;
        try {
            const response = await fetch(url);
            return await response.json();
        } catch (err) {
            return console.log(err, "-----#############------error-----#############------ in getUpOrCrossSellProducts");
        }
    },

    /**
     * Return products list.
     *
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     *
     * @return {Promise<object>}
     * @param query
     * @param lang
     * @param selectedRate
     */

    // getSeachProducts: ({
    //                        query, options = {
    //         lang: "",
    //         currency: ""
    //     }
    //                    }: { query: any, options?: { lang: any, currency?: any } }): Promise<object> => {
    //     let locale = "en";
    //     let currency = "AMD";
    //
    //     if (options.lang) {
    //         locale = options.lang;
    //     }
    //
    //     if (options.currency) {
    //         currency = options.currency;
    //     }
    //     return fetch(`/db/products?search=${query}&locale=${locale}&currency=${currency}&isSearch=true`)
    //         .then((response) => {
    //             return response.json()
    //         });
    // },
    getSearchProducts: async (query: string, lang: string, selectedRate?: {
        exchange_rate: { rate: string } | null,
        symbol?: string
    }): Promise<object> => {
        let locale = "en";

        if (lang) {
            locale = lang;
        }

        const response = await fetch(`/db/searchedProducts?locale=${locale}&search=${query}&rate=${selectedRate?.exchange_rate?.rate}&symbol=${selectedRate?.symbol}`);
        return await response.json();
    },

    getPosts: async (
        filterCategory = "all",
        blogsQty = 3,
        locale = "en"
    ): Promise<any> => {
        const response = await fetch(`db/cms/posts?filterCategory=${filterCategory}&limit=${blogsQty}&locale=${locale}`);
        return await response.json();
    },

    getForms: async (
        locale = "en"
    ): Promise<any> => {
        const response = await fetch(`/db/all-forms?locale=${locale}`);
        return await response.json();
    },

    getFormById: async (
        locale = "en",
        form_id = 1
    ): Promise<any> => {
        const response = await fetch(`/db/form-by-id?form_id=${form_id}&locale=${locale}`);
        return await response.json();
    },

    getTranslations404: async ({locale}: { locale: string }): Promise<object> => {
        const detectLocale = !!locale && locale !== "catchAll" ? locale : "en"
        const response = await fetch(`/db/translations-get?locale=${detectLocale}`);
        const result = await response.json();
        return result[detectLocale]?.translations;
    },

    getCoreConfigs404: async ({selectedLocale, footerTrackItl = {}}: { selectedLocale: string, footerTrackItl: Record<string, string> }): Promise<any> => {
        try {
            const response = await fetch(`/db/core-configs?locale=${selectedLocale}`);
            const {
                theme_blog_active: {value: blogValue = "0"} = {},
                general_info_general_phone: {value: phoneValue = "+1 929 336 4318"} = {},
                catalog_inventory_stock_options_backorders: {value: backOrderValue = "0"} = {},
                catalog_products_guest_checkout_allow_guest_checkout: {value: allowCheckoutValue = "0"} = {},
                general_content_footer_footer_content: [{value: footerCopyRightValue = ""} = {}] = [],
                general_content_footer_footer_powered: [{value: footerPoweredValue = ""} = {}] = [],
                theme_subscription_active: {value: subscriptionActiveValue = "1"} = {},
                sales_tracking_tracking_active: {value: salesTrackingValue = "1"} = {},
                theme_contact_us_active: {value: contactActiveValue = "1"} = {},
                general_info_general_footer_address: [{value: footerAddressValue = ""} = {}] = [],
                general_info_general_footer_email: {value: footerEmailValue = ""} = {},
                customer_settings_social_login_enable_facebook: {value: enableFB = "0"} = {},
                customer_settings_social_login_enable_google: {value: enableGoogle = "0"} = {},
                customer_settings_social_login_enable_twitter: {value: enableTwitter = "0"} = {},
                customer_settings_social_login_enable_linkedin: {value: enableLi = "0"} = {},
                customer_settings_social_login_enable_github: {value: enableGit = "0"} = {},
                general_content_custom_scripts_custom_javascript: {value: customScriptAnalyze = ""} = {},
                general_gdpr_general_title: [{value: gdprTitle = ""} = {}] = [],
                general_gdpr_general_content: [{value: gdprContent = ""} = {}] = [],
                general_gdpr_general_active: [{value: gdprValue = "0"} = {}] = [],
                blog_meta_options_meta_title: [{value: post_meta_title = ""} = {}] = [],
                blog_meta_options_meta_description: [{value: post_meta_description = ""} = {}] = [],
                blog_meta_options_meta_keywords: [{value: post_meta_keywords = ""} = {}] = [],
                general_content_custom_scripts_custom_css: {value: customCssAnalyze = ""} = {},
                general_cash_control_version: {value: cacheControlVersion = ""} = {}
            } = await response.json();
            const hasBlog = !!parseFloat(blogValue) ? "BlogActive" : "BlogInActive";
            const socials = [
                {key: "Facebook", value: enableFB},
                {key: "Google", value: enableGoogle},
                {key: "Twitter", value: enableTwitter},
                {key: "Linkedin", value: enableLi},
                {key: "Github", value: enableGit}
            ];
            const isSocialLinksActive = socials.filter(({value}) => parseFloat(value) === 1);
            const showSubscriptionStyle = !!parseFloat(subscriptionActiveValue) ? "block" : "none";
            const showFooterEmailStyle = !!footerEmailValue ? "block" : "none";
            const showFooterAddressStyle = !!footerAddressValue ? "block" : "none";
            const settings = {
                Tracking: salesTrackingValue,
                ContactWithUs: contactActiveValue
            };
            const arrFooterTrack = Object.entries(settings)
                ?.filter(([_, value]) => value === "1")
                .map(([key, _]) => ({href: `/${key.toLowerCase().replace(/\s+/g, '-')}`, id: footerTrackItl[key]}));
            return {
                telephone: phoneValue,
                selectedLocale,
                hasBlog,
                blogValue,
                gdprValue,
                gdprTitle,
                gdprContent,
                backOrderValue,
                isSocialLinksActive,
                allowCheckoutValue,
                footerCopyRightValue,
                footerPoweredValue,
                showSubscriptionStyle,
                showFooterEmailStyle,
                footerEmailValue,
                footerAddressValue,
                showFooterAddressStyle,
                arrFooterTrack,
                customScriptAnalyze: customScriptAnalyze ?? null,
                customCssAnalyze: customCssAnalyze ?? null,
                salesActiveSettings: settings,
                post_metas: {
                    meta_title: post_meta_title,
                    meta_description: post_meta_description,
                    meta_keywords: post_meta_keywords,
                    share_pic: "configuration/logo/logo.webp",
                },
                cacheControlVersion
            };
        } catch (err) {
            console.log(err, "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
        }

    },

    getRequestInfo404: async (): Promise<{
        dbName: string;
        domain: string;
        logoPath: string;
        ONLY_FOR_TWO_KINGS: boolean
    }> => {
        const response = await fetch(`/db/request-info`);
        return await response.json();
    },

    getChannelInfo404: async ({ locale }: { locale?: string }): Promise<any> => {
        const response = await fetch(`/db/channel-info`);
        const [{
            currencies_new = [],
            locales = [],
            default_locale_id = 1,
        }] = await response.json();
        const {code: defaultLangCode = "en"} = locales.find(({id: langId}: ILocales) => langId === default_locale_id) || {}
        const selectedLocale = !!locale && locale !== "catchAll" ? locale : defaultLangCode;
        const {locale_image: selectedLocaleImg} = locales.find((lang: ILocales) => lang?.code === selectedLocale) || {}
        const filteredLocale = locales.filter((lang: ILocales) => lang?.code !== selectedLocale)
        return {
            locales: filteredLocale,
            currencies: currencies_new,
            selectedRate: currencies_new[0],
            selectedLocaleImg,
            selectedLocale,
        };
    },
    postTranslationsNew404: async ({ locale }: { locale: string }): Promise<any> => {
        try {
            const response = await fetch(`/db/translations-new`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    intlToFind: NotFoundPageTranslations,
                    locale
                })
            });
            return await response.json();

        } catch (err) {
            console.error(err, "getTranslationsNew404")
        }
    },
    getSocialsFooter404: async (): Promise<any> => {
        try {
            const response = await fetch(`/db/socials-footer`);
            return await response.json();
        } catch (err) {
            console.error(err, "getTranslationsNew404")
        }
    },

    getHeaderFooterMenus404: async ({ locale }: { locale: string }): Promise<any> => {
        try {
            const response = await fetch(`/db/header-footer-menus?locale=${locale}`);
            const menus = await response.json()
            const headerMenus = menus.filter((item: { type: string }) => item.type === "header")
                .map(({url_key, slug, new_tab, ...menu}: { url_key: string, slug: string, new_tab: boolean, name: string }) => {
                    if (url_key) {
                        return {
                            ...menu,
                            target: new_tab ? {target: "_blank"} : {},
                            url_key,
                            slugCheckout: slug
                        }
                    }
                    return {
                        ...menu,
                        target: new_tab ? {target: "_blank"} : {},
                        url_key: `/page/${slug}`,
                        slugCheckout: slug
                    }
                })
                .sort((a: { position: number }, b: { position: number }) => a.position - b.position)
            const footerMenus = menus.filter((item: { type: string }) => item.type === "footer")
                .map(({url_key, slug, new_tab, ...menu}: { url_key: string, slug: string, new_tab: boolean }) => {
                    if (url_key) {
                        return {
                            ...menu,
                            target: new_tab ? {target: "_blank"} : {},
                            url_key,
                            slugCheckout: slug
                        }
                    }
                    return {
                        ...menu,
                        target: new_tab ? {target: "_blank"} : {},
                        url_key: `/page/${slug}`,
                        slugCheckout: slug
                    }
                })
                .sort((a: { position: number }, b: { position: number }) => a.position - b.position)
            return ({ setMenuList: headerMenus || false, setFooterMenuList: footerMenus });
        } catch (err) {
            console.error(err, "getHeaderFooterMenus404")
        }
    },

    getCategories404: async ({ locale, ONLY_FOR_TWO_KINGS }: { locale: string, ONLY_FOR_TWO_KINGS: boolean }): Promise<any> => {
        try {
            const response = await fetch(`/db/categories-new?locale=${locale}`);
            const categories = await response.json()
            const categoriesThemeHandle = {"true": categories[0]?.children, "false": categories};
            return categoriesThemeHandle[`${ONLY_FOR_TWO_KINGS}`];
        } catch (err) {
            console.error(err, "getTranslationsNew404")
        }
    },

    /************** Cart Data Requests **********************/

    cartAddItem: async (attr: { productId: number, body: any, addToast?: (error?: boolean) => void }) => {
        try {
            const {productId, body, addToast} = attr
            const response = await fetch(`/api/checkout/cart/add/${productId}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
            if (response.status !== 500) {
                if (addToast) {
                    addToast(false)
                }
                return await response.json()
            } else {
                if (addToast) {
                    addToast(true)
                }
            }
        } catch (err) {
            console.log(err)
        }
    },

    cartRemoveItem: async (attr: { productId: number, queryParam: string }) => {
        try {
            const {productId, queryParam} = attr
            const response = await fetch(`/api/checkout/cart/remove-item/${productId}${queryParam}`)
            return await response.json()
        } catch (err) {
            console.log(err)
        }
    },

    cartQuantityUpdate: async (attr: { body: any }) => {
        try {
            const {body} = attr;
            const response = await fetch("/api/checkout/cart/update", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
            return await response.json();
        } catch (err) {
            console.log(err)
        }
    },

    getCartData: async (attr: { cartToken: string, customerToken: string, locale: string, currCode: string }) => {
        try {
            const {cartToken, customerToken, locale, currCode} = attr
            return fetch(`${megaUrl}/api/checkout/cart?api_token=${cartToken}${customerToken}&locale=${locale}&currency=${currCode}`)
                .then(res => res.json())
        } catch (err) {
            console.log(err)
        }
    },

    /************************* Requests with Controllers ***********************************/

    /**** returns SEO for home page ****/
    /** TODO IMPORTANT!!! Get_Channels doesn't have locale key **/

    async getHomeMetaAsync(locale: any, models: any) {
        return PageControllers
            .Get_Channels(locale, models)
            .then((meta: any) => {
                return meta
            });
    },


    /**** returns home page products ****/
    async getHomeProductsAsync(options: { locale: any; limit?: number; selectedRate: any, isMobile: boolean }, models: any) {
        return ProductController
            .Get_New_And_Featured_Products(options, models)
            .then((products: any) => products);
    },

    async getSearchedProducts(models: any, locale: string, query: string, selectedRate: {
        exchange_rate: { rate: string } | null
    }) {
        return ProductController
            .Get_Searched_Products(models, locale, query, selectedRate)
            .then((products: any) => {
                return {data: products};
            });
    },

    async getCustomerGroups(models: any,) {
        return ProductController
            .Get_Customer_Groups(models)
            .then((customerGroups: any) => {
                return customerGroups;
            });
    },

    async getChannelInfo(models: any, reqHeaders: any, domain: string) {
        return SettingsController.Get_Chanel_Info(models, reqHeaders, domain)
            .then(({channel_info = {}}: any) => channel_info)
    },

    async getAllProduct(models: any, locale: string, query: any, findAllAttributes: any, selectedRate: {
        exchange_rate: { rate: string } | null
    }) {
        return ProductController
            .Get_All_Product(models, locale, query, findAllAttributes, selectedRate)
            .then((products: any) => {
                return products;
            });
    },

    async getBuilderDataAsync(pageId: number, locale: string, models: any, dbName: string, domain: string, selectedRate: ICurrencies) {
        const response = await BuilderController.Get_Data(pageId, locale, models, dbName, domain, selectedRate)
        return JSON.parse(JSON.stringify(response)) || []
    },

    async sliderData(selectedLocale: any, models: any, isMobile: boolean) {
        return SettingsController
            .Get_Slider_Data(selectedLocale, models, isMobile)
            .then((response: any) => {
                return response;
            });
    },
    /**** returns categories ****/
    async getCategoriesAsync(locale: any, models: any) {
        return CategoryControllers
            .Get_Categories(locale, models)
            .then((response: any) => {
                return response;
            });
    },

    /**** returns categories page [filters] ****/
    // async getFiltersAsync(options: any, models: any) {
    //     return FilterControllers
    //         .Get_Filters(options, models)
    //         .then((response: any) => {
    //             return response;
    //         });
    // },

    /**** returns all [filters] ****/
    // async getAllFiltersAsync(options: any, models: any) {
    //     return FilterControllers
    //         .Get_All_Filters(options, models)
    //         .then((response: any) => {
    //             return response;
    //         });
    // },

    async getAllAttributesAsync(options: any, models: any) {
        return FilterControllers
            .Get_All_Attributes(options, models)
            .then((response: any) => {
                return response;
            });
    },
    async getAllFiltersCatalog(locale: string, models: any) {
        return FilterControllers
            .Get_Filters_Catalog(locale, models)
            .then((response: any) => {
                return response;
            });
    },
    async getFiltersCategory(category_id?: string | number, models?: any, locale?: string) {
        return FilterControllers
            .Get_Filters_By_Category(category_id, models, locale)
            .then((response: any) => {
                return response;
            });
    },
    async getSocialsFooter(models: any) {
        return SettingsController.Get_Socials(models)
            .then((response: any) => {
                return JSON.parse(JSON.stringify(response)) || []
            })
    },

    async getFields(locale: string, models: any) {
        return FieldsController
            .Get_Fields(locale, models)
            .then((response: any) => {
                return response;
            });
    },

    async getProductOptions(options: { locale: string }, models: any) {
        return ProductController
            .Get_Product_Options(options, models)
            .then((response: any) => {
                return response
            });
    },

    /**** returns categories page [products] ****/

    async Get_Category_Products(options: any, models: any, sortBy?: boolean, isInfitieScroll?: boolean) {
        return ProductController
            .Get_Category_Products(options.slug, options.locale, options.limit, models, options.page, sortBy, isInfitieScroll)
            .then((response: any) => {
                return response
            })
    },

    /**** returns categories page [products] ****/

    async Get_New_Category_Products(options: any, models: any, sortBy?: boolean, isInfitieScroll?: boolean, findAllAttributes?: any, filterValues?: any) {
        return ProductController
            .Get_New_Category_Products(options.slug, options.locale, options.limit, models, options.page, sortBy, findAllAttributes, isInfitieScroll, filterValues, options.selectedRate)
            .then((response: any) => {
                return response
            })
    },

    async Get_Filtered_Category_Products(models: any, responseProductList: any, filterValues: any, options: any) {
        return ProductController
            .Get_Filtered_Category_Products(models, responseProductList, filterValues, options)
            .then((response: any) => {
                return response
            })
    },


    async GetTranslations(locale: string, intlToFind: Array<Record<string, string>>, models: any) {
        return SettingsController
            .Get_Translations_New(locale, intlToFind, models)
            .then((response: any) => JSON.parse(JSON.stringify(response)))
    },


    /**** returns all products ****/

    async Get_All_Products(options: any, models: any) {
        return ProductController
            .Get_All_Products(options.locale, models)
            .then((response: any) => {
                return response
            })
    },


    /****************** return Blogs  ***************/
    getAsyncBlogs: async function (options: {
        locale: string | undefined,
        page: string | number | string[],
        limit: number,
        dbName: string
    }, models: any) {
        return BlogControllers
            .Get_Blogs(options, models)
            .then((response: any) => {
                return response;
            });
    },
    async getAsyncPagesBySlug(options: { dbName: string; }, models: any) {
        return PageControllers
            .Get_Page_By_Slug(options, models)
            .then((response: any) => {
                return response
            });
    },
    async getAsyncBlogsBySlug(options: { locale: string, url_key: string, dbName: string }, models: any) {
        return BlogControllers
            .Get_Blog_By_Slug(options, models)
            .then((response: any) => {
                return response
            });
    },

    /****************** return -- NEW -- Blogs  ***************/

    async getAsyncPostsBySlug(options: { locale: string, slug: string, dbName: string }, models: any) {
        return PostControllers
            .Get_Post_By_Slug(options, models)
            .then((response: any) => {
                return response
            });
    },
    getAsyncPosts: async function (options: {
        domain: string,
        locale: string | undefined,
        page: string | number | string[],
        limit: number,
        filterTags?: Array<string>,
        filterCategory?: string,
        dbName: string
    }, models: any) {
        return PostControllers
            .Get_All_Posts(options, models)
            .then((response: any) => {
                return response;
            });
    },

    getAsyncPostCategories: async function (options: {
        locale: string | undefined,
    }, models: any) {
        return PostControllers
            .Get_Posts_Categories(options, models)
            .then((response: any) => {
                return response;
            });
    },

    async getAsyncPostsCatBySlug(options: { locale: string, slug: string, dbName: string }, models: any) {
        return PostControllers
            .Get_Post_Cat_By_Slug(options, models)
            .then((response: any) => {
                return response
            });
    },

    getAsyncTags: async function (options: {
        locale: string | undefined,
    }, models: any) {
        return PostControllers
            .Get_All_Tags(options, models)
            .then((response: any) => {
                return response;
            });
    },

    getAsyncTagsBySlug: async function (options: {
        locale: string | undefined,
        slug: string
    }, models: any) {
        return PostControllers
            .Get_Tags_By_Slug(options, models)
            .then((response: any) => {
                return response;
            });
    },

    /*************************** PRODUCT INNER PAGE ASYNC REQUESTS ************************************/

    getProductBySlugAsync(slug: string, options: {
        locale: any,
        selectedRate?: { exchange_rate: { rate: string } | null }
    }, models: any) {
        return ProductController
            .Get_Product_For_Product_Inner_Page(slug, options, models)
    },

    getRelatedProductsAsync(options: any, models: any) {
        return ProductController
            .Get_Related_Products(options, models)
    },

    getBundleProductAsync: (id: any, models: any) => {
        return ProductController
            .Get_Bundle_Prods(id, models)
    },

    async getShopId(neededIds: any, models: any) {
        return SettingsController
            .Get_ShopId(neededIds, models)
            .then((res: { value: any; }) => {
                if (res?.value) {
                    return res.value;
                }
                return res
            })

    },


// get usd value

    getUsdCurrentValue: () => {
        return fetch(`https://api.coingecko.com/api/v3/simple/price?ids=binance-usd&vs_currencies=usd`)
            .then(res => res.json())
    },

    /********************************** Checkout Page queries ***************************************************/
    getPayments: (locale = "en") => {
        let hasLocale = locale ? `?locale=${locale}` : ""
        return fetch(`${megaUrl}/api/payments${hasLocale}`)
            .then(res => res.json())
    },

    checkSuspend: () => {
        return fetch(`${megaUrl}/api/susp`)
    },


    checkOrder: (orderId: any, transactionID: any) => {
        let hasOrderId = orderId && transactionID ? `order_id=${orderId}&trans_id=${transactionID}` : ""
        return fetch(`${megaUrl}/api/crypto-check?${hasOrderId}`)
            .then(res => res.json())
    },

    getStripe: () => {
        return fetch(`${megaUrl}/api/checkout/getpk`)
            .then(res => res.json())
    },

    getCountryState: () => {
        return fetch(`${megaUrl}/api/country-states?pagination=0`)
            .then(res => res.json())
    },

    getCountries: () => {
        return fetch(`${megaUrl}/api/countries?pagination=0`)
            .then(res => res.json())
    },

    getCustomerToken: async (token: any, signal: AbortSignal) => {
        const res = await fetch(`${megaUrl}/api/customer/get?token=${token}`, {signal});
        return await res.json();
    },

    getAddresses: (token: any, signal: AbortSignal) => {

        return fetch(`${megaUrl}/api/addresses?pagination=0&token=${token}`, {signal})
            .then(res => res.json())
    },

    getUserAddress: async (addressId: string, body: any) => {
        let option = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }
        return fetch(`${megaUrl}/api/addresses/${addressId}`, option)
            .then((response) => response.json())
    },

    // getCartToken: (token: any) => {
    //     const headers = {
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json',
    //     }
    //     return fetch(`${megaUrl}/api/checkout/cart?token=${token}`, {
    //         method: 'GET',
    //         headers: headers,
    //     })
    //         .then((res) => res.json())
    // },
    //
    getCartCoupon: (coupon: any, token: any) => {
        const requestOptions = {
            method: coupon ? "DELETE" : "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                code: coupon,
                token: token,
            }),
        }
        return fetch(`${megaUrl}/api/checkout/cart/coupon`, requestOptions)
            .then((res) => res.json())
    },

    getCartCustomer: async (customer: any, token: any) => {
        const trueToken = (customer && customer.token) ? customer.token : token.cartToken
        const body = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }
        return fetch(`/api/checkout/cart?token=${trueToken}`, body)
            .then(res => res.json())
    },

    setSaveAddress: (billing: any, shipping: any, cartToken: any, token: any, isFromPayButton: boolean = false, locale: string) => {
        // const {general: {roomID}} = store.getState()
        const roomID = sessionStorage.getItem("roomID")

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        /***
         FIXME for further
         I know, that solving this problem in this way is the worst solution, but in this case I had to...
         Do not judge me strict, I didn't want to do this .....
         billing: shipping,
         shipping: billing,
         ***/
        const mainBody = {
            // billing: shipping,
            // shipping: billing,
            billing,
            shipping,
            api_token: cartToken,
            token,
            locale
        }

        if (roomID) {
            Object.assign(mainBody, {room_id: roomID})
        }

        const body = !isFromPayButton ? {
            ...mainBody,
            is_order: 1
        } : {
            ...mainBody
        }

        if (!token) {
            delete body.token
        }


        const options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }

        return fetch(`${megaUrl}/api/checkout/save-address`, options)
    },

    setSaveShipping: (shippingMethod: any, cartToken: any, token: string, locale: string) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        const body = {
            shipping_method: shippingMethod === "" ? "free_free" : shippingMethod,
            api_token: cartToken,
            token: token,
            locale
        }
        ////FIXME FIX THIS PART
        // if (!token) {
        //     delete body.token
        // }

        const save_shipping = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }

        let tokenURL = token ? "?token=" + token : "";
        return fetch(`${megaUrl}/api/checkout/save-shipping${tokenURL}`, save_shipping)
    },

    setSavePayment: (payment: any, cartToken: any, token: any) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        const body = {
            payment: {method: payment},
            api_token: cartToken,
            token: token,
        }

        if (!token) {
            delete body.token
        }

        const options_payment = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }
        return fetch(`${megaUrl}/api/checkout/save-payment`, options_payment)
    },

    setSaveOrder: (cartToken: any, token: any) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        const body = {
            api_token: cartToken,
            token: token,
        }

        // if (!token) {
        //     delete body.token
        // }

        const options_order = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }
        let tokenURL = token ? "?token=" + token : "";
        return fetch(`${megaUrl}/api/checkout/save-order${tokenURL}`, options_order)
            .then(res => res.json())
    },


    /******************* shipping methods *********************/

    getShippingMethods: (locale: any, token: any) => {
        const realToken = token ? `&token=${token}` : ""
        return fetch(`${megaUrl}/api/shipping?locale=${locale}${realToken}`)
            .then(res => res.json())
    },

    /********************* cart requests ************************/

    getCartTranslation: (cartToken: { cartToken: any; }, customer: { token: any; }, locale: any) => {
        const realToken = customer && customer.token ? `&token=${customer.token}` : ""
        return fetch(`${megaUrl}/api/checkout/cart?api_token=${cartToken.cartToken}${realToken}&locale=${locale}`)
            .then(res => res.json())
    },

    /************************* Account component requests *****************************/

    getForgetPassword: (input: any) => {
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({email: input}),
        };
        return fetch(`${megaUrl}/api/customer/forget-password`, options)
            .then(res => res.json())
    },

    getCustomerLogoutToken: (customer: any) => {
        return fetch(`${megaUrl}/api/customer/logout?token=${customer}`)
    },

    getCheckoutCartToken: () => {
        return fetch(`${megaUrl}/api/checkout/cart/token`)
            .then(res => res.json())
    },

    getCustomerLoginToken: ({email, password}: { email: string, password: string }) => {
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        };

        return fetch(`${megaUrl}/api/customer/login?token=true`, options)
            .then(res => res.json())
    },

    getSmartSearchCats: (locale: any, catID: any) => {
        return fetch(`${megaUrl}/db/smart-search/${catID}/${locale}`)
            .then(res => res.json())
    },

    getProductsBySmartSearch: (options: any) => {
        return fetch(`${megaUrl}/db/smart-search/products/${options.slug}/${options.locale}`)
            .then(res => res.json())
    },

    getCoreConf: (locale: string, models: any) => {
        return SettingsController
            .Get_Core_Config(locale, models)
            .then((res: any) => res)
    },

    getLocale: (locale: string, models: any) => {
        return SettingsController
            .Get_Settings(locale, models)
            .then((res: any) => {
                return res.channel_info
            });
    },

    getMenus: async (locale: string, models: any) => {
        try {
            if (locale !== "catchAll") {
                const menus = await CategoryControllers.Get_Menus(locale, models)
                return menus
            }
        } catch (err) {
            throw err;
        }
    },

    getCustomFields: async (locale: string, models: any) => {
        try {
            return await SettingsController.Get_Custom_Fields(locale, models)
        } catch (err) {
            throw err;
        }
    },
    getAllScrollProducts: async (models: any, type: string | null, locale: string, limit: number | null, selectedRate: {
        exchange_rate: { rate: string } | null
    }) => {
        return ProductController
            .Get_Scroll_Products(models, type, locale, limit, selectedRate)
            .then((res: any) => res)
    },

    /************************* Subscription **************************************/

    setSubscription: async (email: string) => {
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({subscriber_email: email})
        }

        return fetch(`${megaUrl}/api/subscribe`, options)
            .then(res => res.json())
    },

    /************************* Smart search requests *****************************/

    submitFormBuilder: async (object: FormData, code: string) => {
        const options = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
            },
            body: object
        }

        return fetch(`${megaUrl}/api/formbuilder/${code}/store`, options)
            .then(res => res.json())
            .catch(err => {
                console.log(err, "-----#############------error-----#############------ in submitFormBuilder")
            })
    },
    getEmptyCart: async () => {
        const options = {
            method: "GET",
            headers: {
                'Accept': 'application/json',
            },
        }

        return fetch(`${megaUrl}/api/checkout/cart/empty`, options)
            .then(res => res.json())
            .catch(err => {
                console.log(err, "-----#############------error-----#############------ in getEmptyCart")
            })
    },
};


export default shopApi;
