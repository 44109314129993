import store from "../store";
import generalAction from "../api/generalAction";
import shopApi from "../api/shop";
import cookie from "cookie";

export const url = {
    home: () => "/",

    catalog: () => "/catalog",

    category: (category) => {
        let stringQuery = `/catalog/${category.slug ? category.slug : category?.department?.slug || category.link.slug}`
        if (category && category.query && category.query.page) {
            return `${stringQuery}?page=1`;
        }
        if (category && category.query && category.query.currencies) {
            return `${stringQuery}?currencies=${category.query?.currencies}`;
        } else {
            return `${stringQuery}`;
        }
    },

    product: (product) => {
        // if (product.product_flat && product?.product_flat.length > 0) {
        if (product.flats && product?.flats.length > 0) {

            // return `/${product.product_flat[0].url_key}`;
            return `/${product.flats[0].url_key}`;
        } else {
            return `/${product.url_key}`;

        }


    },
}

export function convertStyles(settings) {
    let top = 0;
    let right = 0;
    let bottom = 0;
    let left = 0;
    let paddTop = 0;
    let paddRight = 0;
    let paddBottom = 0;
    let paddLeft = 0;
    let fontFamily = "";
    let image = [];
    let color = "";
    let width = "";
    let height = "initial";
    let align = "";
    let richEditorValue = "";
    let accordionTitle = "";
    let accordionDescription = "";
    let borderTopLeftRadius = "";
    let borderTopRightRadius = "";
    let borderBottomLeftRadius = "";
    let borderBottomRightRadius = "";
    let position = "";
    let zIndex = "";
    let positionLeft = "";
    let positionRight = "";
    let positionTop = "";
    let positionBottom = "";
    let backgroundColor = "";
    let animationAosInit = '';
    let display='';
    let justifyContent='';


    settings.map((item) => {
        Object.keys(item).forEach((key) => {
            if (key !== undefined) {
                switch (key) {
                    case 'data-aos':
                        return (animationAosInit = item['data-aos']);
                    case "marginTop":
                        return (top = item.marginTop);
                    case "marginRight":
                        return (right = item.marginRight);
                    case "marginBottom":
                        return (bottom = item.marginBottom);
                    case "marginLeft":
                        return (left = item.marginLeft);
                    case "paddingTop":
                        return (paddTop = item.paddingTop);
                    case "paddingRight":
                        return (paddRight = item.paddingRight);
                    case "paddingBottom":
                        return (paddBottom = item.paddingBottom);
                    case "paddingLeft":
                        return (paddLeft = item.paddingLeft);
                    case "font-family":
                        return (fontFamily = item["font-family"]);
                    case "color":
                        return (color = item.color);
                    case "width":
                        return (width = item.width);
                    case "height":
                        return (height = item.height);
                    case "text-align":
                        return (align = item["text-align"]);
                    case "border-top-left-radius":
                        return (borderTopLeftRadius = item["border-top-left-radius"]);
                    case "border-top-right-radius":
                        return (borderTopRightRadius = item["border-top-right-radius"]);
                    case "border-bottom-left-radius":
                        return (borderBottomLeftRadius = item["border-bottom-left-radius"]);
                    case "border-bottom-right-radius":
                        return (borderBottomRightRadius = item["border-bottom-right-radius"]);
                    case "position":
                        return (position = item.position);
                    case "z-index":
                        return (zIndex = item["z-index"]);
                    case "left":
                        return (positionLeft = item.left);
                    case "right":
                        return (positionRight = item.right);
                    case "top":
                        return (positionTop = item.top);
                    case "bottom":
                        return (positionBottom = item.bottom);
                    case "background-color":
                        return (backgroundColor = item["background-color"])
                    case 'display':
                        return (display =
                            item['display'])
                    case 'justify-content':
                        return (justifyContent =
                            item['justify-content'])
                    default:
                        return false;
                }
            }
        });
    });
    const parentStyles = {
        display: display,
        justifyContent: `${justifyContent}`,
        textAlign: `${align}`,
    };

    let animationAos = {
        ['data-aos']: animationAosInit,
    }
    const extractNumberAndUnitFromString = (str) => {
        if (str) {
            const match = str.match(/(\d+)([a-zA-Z%]*)/);
            return match ? { number: parseInt(match[1], 10) || "", resizeVal: match[2] } : {};
        }
        return { number: str, resizeVal: 'px' }
    };
    const { number: widthNumber = 0, resizeVal: widthResizeVal } = extractNumberAndUnitFromString(width) || {}
    const { number: heightNumber= 0, resizeVal: heightResizeVal } = extractNumberAndUnitFromString(height) || {}

    const styles = {
        margin: `${top}px ${right}px ${bottom}px ${left}px`,
        padding: `${paddTop}px ${paddRight}px ${paddBottom}px ${paddLeft}px`,
        fontFamily: `${fontFamily}`,
        color: color,
        backgroundColor: backgroundColor,
        width: `${!!widthNumber ? widthNumber : ""}${!!widthResizeVal ? widthResizeVal : "px"}`,
        height: !!heightNumber ? `${heightNumber}${heightResizeVal || "px"}` : "unset",
        borderTopLeftRadius: `${borderTopLeftRadius}px`,
        borderTopRightRadius: `${borderTopRightRadius}px`,
        borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
        borderBottomRightRadius: `${borderBottomRightRadius}px`,
        position: position,
        zIndex: `${zIndex}px`,
        left: `${positionLeft}px`,
        right: `${positionRight}px`,
        top: `${positionTop}px`,
        bottom: `${positionBottom}px`,
    };
    return {styles, parentStyles, animationAos}
}

export function runFbPixelEvent(eventData) {
    const {
        general: {fbPixel},
    } = store.getState();
    setTimeout(() => {
        if (fbPixel) {
            typeof window !== undefined ? fbq("track", eventData?.name) : null;
        }
    }, 500);
}

const replacer = (string, filterName) => {
    const index = string.indexOf(filterName);
    if (index > -1) {
        const endIndex = string.includes('&') ? string.indexOf('&', index) + 1 : string.length;
        const substringToRemove = string.substring(index, endIndex);
        const newString = string.replace(substringToRemove, '');
        return replacer(newString, filterName); // Recursive call
    }
    return string;
};

export const rightUrl = (path, router) => {
    if (router.asPath.includes("?")) {
        let x = `${path}${router.asPath.slice(router.asPath.indexOf("?"))}`
        let y = replacer(clearQueryPath(x), "page");
        return y.indexOf("?") === y.length - 1 ? y.slice(0, y.length - 1) : y
    }
    return path
}

const clearQueryPath = (string) => {
    let ab;
    if (string.includes("filter_")) {
        ab = replacer(string, "filter")
        if (ab.includes("filter_")) {
            return clearQueryPath(ab)
        }
        return ab
    }
    return string
}

export const replaceDescr = (str) => {
    if (str) {
        let obj = {}
        let newStr = str
        while (newStr.includes("<table")) {
            let start = newStr.lastIndexOf("<table")
            let end = newStr.lastIndexOf("</table>") + 8

            obj[newStr.slice(start, end)] = [start, end]

            newStr = newStr.replace(newStr.slice(start, end), '')
        }
        for (let key in obj) {
            let start = obj[key][0]
            let end = obj[key][1]
            let value = `<div className="table_wrapper" style="overflow: scroll">${key}</div>`
            str = str.replace(str.slice(start, end), value)
        }
        return str
    }
}

export const detectLocale = (arr, locale) => {
    let newArr;
    if (arr && arr.length > 0) {
        newArr = arr.find(el => el.locale_code === locale)
        if (newArr === undefined) {
            return {value: false}
        }
        return newArr
    }
    return {value: false}
}

export const genKey = (message) => {
    return message?.replaceAll(" ", "_").toLowerCase()
}

export const spaceToUpperCaseConvertor = (input) => {
    return input
        ?.replace(/[^a-zA-Z]+/g, ' ')
        .split(' ').map((word, index) => {
            if (index === 0) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
}

export const idGen = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export const createBuilderData = (array, arrayElement) => {
    let data = array?.map((el) => {
        if (Object.keys(el)[0] === arrayElement) {
            return Object.values(el)[0]
        }
    })
    data = data?.filter(element => element !== undefined);
    return data[0]
}

export const isDefaultImage = (url) => {
    return url.split('/').includes('_next')
}

export const checkDetail = (details) => {
    if (details && details.length > 0) {
        return details.some(el => +el.attribute?.[0]?.is_visible_on_front === 1)
    } else return false
}

export const createRightDate = (date) => {
    let day = new Date(date);

    let dd = String(day.getDate()).padStart(2, "0");
    let mm = String(day.getMonth() + 1).padStart(2, "0");
    let yyyy = day.getFullYear();
    return dd + "/" + mm + "/" + yyyy
}

/*** server side repeating actions ***/

export const repeatActions = async (req, res, locale, selectedCurrency = "") => {
    const dbName = req.headers["x-forwarded-host"];
    const domain = req.defaultDB.db_name;
    // const logo = req.assets.logo_image
    const logo = ""

    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

    const generalOptions = {
        dbName,
        domain,
        locale,
        selectedCurrency,
    }

    try {
        const generalResult = await generalAction.action("General", generalOptions, req.m);
        const {
            locale: defaultLocaleSelected,
            rate,
            currency,
            dispatches,
            menus,
            categories,
            coreConfigs,
            locales,
            translations,
        } = generalResult;

        let selectedRate;
        const cookies = cookie.parse(req.headers.cookie || "");
        const selectedCurrency = cookies?.selectedCurrency
        if (!!selectedCurrency) {
            selectedRate = {
                BaseCurrId: rate.BaseCurrId,
                ...rate.currencies_new?.find((item) => String(item.code) === String(selectedCurrency))
            }
        } else {
            const findItem = rate.currencies_new?.find((item) => item.id === rate.BaseCurrId)
            if (findItem.exchange_rate === null) {
                selectedRate = {
                    ...findItem,
                    exchange_rate: {
                        rate: 1
                    },
                    BaseCurrId: rate.BaseCurrId
                }
            } else {
                selectedRate = {
                    ...findItem,
                    BaseCurrId: rate.BaseCurrId
                }
            }
        }

        const selectedLocale = locale !== "catchAll" ? locale : defaultLocaleSelected;

        const metaTags = await shopApi.getHomeMetaAsync(selectedLocale, req.m);

        return {
            selectedLocale,
            selectedRate,
            currency,
            dispatches,
            dbName,
            domain,
            menus,
            categories,
            coreConfigs,
            metaTags,
            locales,
            translations,
            logo
        };
    } catch (err) {
        throw err;
    }
}

export const viewSourceContent = (coreConfigs, locale) => {
    const arrFooterTrack = [];
    let showSubFooter = "none";
    let isBlogActive = false;

    if (coreConfigs.sales_tracking_tracking_active?.value === "1" || !coreConfigs.hasOwnProperty("sales_tracking_tracking_active")) {
        arrFooterTrack.push({href: "/tracking", id: "Tracking"})
    }
    if (coreConfigs.theme_contact_us_active?.value === "1" || !coreConfigs.hasOwnProperty("theme_contact_us_active")) {
        arrFooterTrack.push({href: "/contact", id: "ContactWithUs"})
    }
    if (coreConfigs.theme_subscription_active?.value === "1" || !coreConfigs.hasOwnProperty("theme_subscription_active")) {
        showSubFooter = "block";
    }
    if (coreConfigs.theme_blog_active?.value === "1" || !coreConfigs.hasOwnProperty("theme_blog_active")) {
        isBlogActive = true;
    }

    const footerContent = coreConfigs.general_content_footer_footer_content?.find((item) => item?.locale_code === locale);
    const footerToggle = coreConfigs.general_content_footer_footer_toggle?.find((item) => item?.locale_code === locale);
    const post_meta_title = coreConfigs.blog_meta_options_meta_title?.[0]
    const post_meta_description = coreConfigs.blog_meta_options_meta_description?.[0]
    const post_meta_keywords = coreConfigs.blog_meta_options_meta_keywords?.[0]

    return {
        arrFooterTrack,
        isBlogActive,
        showSubFooter,
        footerContent,
        footerToggle,
        post_meta_title,
        post_meta_description,
        post_meta_keywords,
    }
}

export const subtractDomain = (image_path, domain) => {
    /*** to be sure that it removes the exact one ***/
    let slashedDomain = `${domain}/`
    if (image_path && image_path.includes(slashedDomain)) {
        return image_path.replace(slashedDomain, "")
    }
    return image_path
}

export const validate = (currentForm, hasState) => {

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let errors = {}
    for (const [key, value] of Object.entries(currentForm)) {
        if (key !== "additional" && key !== "company_name" &&
            (
                (Array.isArray(value) && !Boolean(value[0])) ||
                (!Array.isArray(value) && typeof value !== "boolean" && !Boolean(value)) ||
                (key === "state" && hasState && value === "no state")
            )) {
            errors[`${key}_error`] = "this field is required"
        }

        if (key === "email" && !regex.test(value)) {
            errors[`${key}_error`] = "invalid email format"
        }
    }
    return errors
}

export const getExactDropdownItems = (array, forWhat, locale, currency) => {
    const currencyLocaleObject = {
        currency,
        language: locale
    }
    return array.filter((e) => e.code !== currencyLocaleObject[forWhat])
}

export const stringToBoolean = (str) => {

    const truePattern = /^(true|1|yes|y|on)$/i;
    const falsePattern = /^(false|0|no|n|off)$/i;

    if (truePattern.test(str)) {
        return true;
    } else if (falsePattern.test(str)) {
        return false;
    }

    if (/^\d+(\.\d+)?$/.test(str)) {
        const numberValue = parseFloat(str);
        return numberValue !== 0;
    }

    return null;
}

export const renderMapData = (initialLayout, forMap = "class") => {
    if (initialLayout && initialLayout.length) {
        return initialLayout?.map((item) => {
            return Object.keys(item).map((element) => {

                return item[Number(element)].map((child) => {
                    if (forMap === "backgroundColor") {
                        return child.backgroundColor
                    } else if (forMap === "alignItems") {
                        return child.alignItems
                    } else if (forMap === "fullScreen") {
                        return child.fullScreen
                    } else if (forMap === "backgroundImage") {
                        return child.backgroundImage
                    } else if (forMap === "bgImageHeight") {
                        return child.bgImageHeight
                    } else if (forMap === "alignItemsRow") {
                        return child.alignItemsRow
                    } else if (forMap === "column") {
                        return child.children
                    }
                    return child.children.map((children) => {
                        return children.class
                    })
                })
            })
        })
    }
}

export const generateTranslateKey = (str = "") => {
    return str.split(" ").map(el => el[0]?.toUpperCase() + el.slice(1))?.join("")
}
