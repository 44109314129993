const {menuAggregate, categoryAggregate, categoryByIdAggregate} = require("./aggregations")
const {parseClone, buildTree} = require("./helpers")

const Get_Menus = async (locale, models) => {
    try {
        const res = await models.menus.aggregate(menuAggregate(locale));
        const modifiedRes = res.map(el => {
            return {
                ...el,
                new_tab: el.new_tab === "on"
            }
        })
        let recursionChildren = buildTree(modifiedRes, null, locale, "page", true);
        return parseClone(recursionChildren);
    } catch (err) {
        console.log(err, "_______ ERROR when getting menus _______")
        throw err;
    }
}

const Get_Categories = async (locale, models) => {
    try {
        const res = await models.categories.aggregate(categoryAggregate(locale));
        let recursionChildren = buildTree(res, null, locale, "catalog", false);
        return parseClone(recursionChildren);
    } catch (err) {
        console.log(err, "_______ ERROR when getting categories _______")
        throw err;
    }
}

const Get_Category_By_Search_Filter = async (catId, locale, models) => {
    try {
        const res = await models.categories.aggregate(categoryByIdAggregate(locale, catId));
        return parseClone(res);
    } catch (err) {
        console.log(err, "_______ ERROR when getting search filter _______")
        throw err;
    }
}

module.exports = {
    Get_Menus,
    Get_Categories,
    Get_Category_By_Search_Filter,
}